import { SVGProps, memo } from 'react'

export type EvCarImageProps = SVGProps<SVGSVGElement> & {
	/**
	 * Defaults to Elecca Themed Colours if not provided.
	 */
	fills?: Partial<{
		fillBody: string
		fillBodyLines: string
		fillFrontLightsBottom: string
		fillWheelArch: string
	}> | null
}

const EvCarImage = ({
	'aria-label': ariaLabel = 'EV',
	fill = 'none',
	fills,
	role = 'img',
	viewBox = '0 0 116 58',
	xmlns = 'http://www.w3.org/2000/svg',
	...props
}: EvCarImageProps): JSX.Element => {
	const fillBackLights = '#F6416C'
	const fillDarkLines = '#263238'
	const fillWheelRims = '#E0E0E0'
	const fillFrontLights = '#E3FFF6'
	const {
		fillBody = '#83ADA9',
		fillBodyLines = '#6EA09B',
		fillFrontLightsBottom = '#ABEDD8',
		fillWheelArch = '#51827E'
	} = fills ?? {}
	return (
		<svg aria-label={ariaLabel} fill={fill} role={role} viewBox={viewBox} xmlns={xmlns} {...props}>
			<title>{ariaLabel}</title>
			<path
				d="M115.157 47.7047L1.62565 44.7574L2.37982 32.4244L15.1666 28.8734H100.646L111.647 34.843L114.149 39.6765L115.157 47.7047Z"
				fill={fillWheelArch}
			/>
			<path
				d="M109.479 29.7249C105.17 23.8977 102.942 21.9066 102.942 21.9066C61.4244 -12.2087 13.726 3.71279 13.726 3.71279C1.22726 13.9459 3.95592 28.1612 3.95592 28.1612C-2.07744 35.1245 1.6214 44.7576 1.6214 44.7576L2.42105 44.7801C2.38315 44.4052 2.3642 44.0227 2.3642 43.6365C2.3642 37.3969 7.47664 32.3347 13.7867 32.3347C20.0967 32.3347 25.2091 37.3931 25.2091 43.6365C25.2091 44.2252 25.1636 44.8026 25.0765 45.3689L91.5685 47.0975C91.2161 46.0063 91.0228 44.8439 91.0228 43.6402C91.0228 37.4006 96.1352 32.3384 102.445 32.3384C108.755 32.3384 113.868 37.3969 113.868 43.6402C113.868 45.0576 113.602 46.4113 113.121 47.66L115.16 47.7125C115.16 47.7125 115.088 45.0126 115.16 40.2504C115.232 35.4882 113.796 35.5595 109.487 29.7324L109.479 29.7249ZM80.0627 21.1942C61.6632 18.5018 35.7144 15.1608 23.0641 15.6333C23.0641 15.6333 23.8183 7.33505 33.0843 6.27012C42.3504 5.20519 53.4469 5.41893 61.4207 6.91133C69.3944 8.40373 80.0627 12.8809 80.0627 12.8809V21.1942ZM100.641 24.3965C100.641 24.3965 95.1385 23.4703 86.9336 22.2216V13.4022C96.3475 18.3481 100.641 23.0465 100.641 23.0465V24.3965Z"
				fill={fillBody}
			/>
			<g style={{ mixBlendMode: 'multiply' }} opacity="0.7">
				<path
					d="M20.0508 17.5903L27.6418 18.1565L35.2251 18.8014C40.2807 19.2476 45.3288 19.7539 50.3768 20.2826C55.421 20.8375 60.4614 21.4038 65.4943 22.075C66.7525 22.2362 68.0107 22.4087 69.2651 22.5924C70.5195 22.7762 71.7778 22.9374 73.0322 23.1399L76.7879 23.7436L80.536 24.3998L76.7727 23.8336L73.0019 23.3199C71.7474 23.1399 70.4854 22.9937 69.231 22.8287C67.9728 22.6637 66.7146 22.5099 65.4564 22.3637C60.4235 21.7525 55.3831 21.1825 50.3351 20.6576C45.2946 20.0988 40.2466 19.6001 35.1986 19.0902L20.0546 17.594L20.0508 17.5903Z"
					fill={fillBody}
				/>
			</g>
			<g style={{ mixBlendMode: 'multiply' }} opacity="0.5">
				<path
					d="M15.5382 20.0168C15.5382 20.0168 54.0426 22.7204 91.5692 28.4613C91.5692 28.4613 88.0409 38.1169 89.2271 43.6215L27.4041 38.9681C27.4041 38.9681 29.299 33.9584 15.5382 20.0131V20.0168Z"
					fill={fillBody}
				/>
			</g>
			<g style={{ mixBlendMode: 'screen' }} opacity="0.5">
				<path
					d="M95.9046 32.7097C95.9046 32.7097 101.32 29.5187 107.782 32.391C108.581 32.7472 109.48 32.1548 109.48 31.2886C109.48 30.7973 109.18 30.3586 108.722 30.1749C107.346 29.6237 104.25 28.6112 100.646 28.8737C100.646 28.8737 102.26 27.4338 106.141 28.0225C106.141 28.0225 104.083 22.6679 95.3134 16.8295C95.3134 16.8295 101.233 20.808 101.923 25.3752L71.9872 21.198C71.9872 21.198 98.133 24.824 95.9084 32.7135L95.9046 32.7097Z"
					fill={fillBody}
				/>
			</g>
			<path
				d="M17.8953 16.2223C17.8953 16.2223 16.7469 -0.977849 51.3706 3.00439C85.9942 6.98289 100.646 23.0469 100.646 23.0469V24.3968C100.646 24.3968 31.6067 12.795 17.8953 16.2223Z"
				stroke={fillBodyLines}
				strokeWidth="2"
				strokeMiterlimit="10"
			/>
			<path
				d="M59.5643 18.4076V15.1566C59.5643 12.018 56.9909 9.47192 53.8187 9.47192H52.6665V17.5901C54.9557 17.8489 57.26 18.1226 59.5643 18.4113V18.4076Z"
				fill={fillDarkLines}
			/>
			<path
				d="M88.1969 22.4123C87.9428 22.3748 87.6888 22.3336 87.4309 22.2961C87.6888 22.3373 87.9466 22.3748 88.1969 22.4123Z"
				fill={fillBodyLines}
			/>
			<path
				d="M100.645 24.3965C100.645 24.3965 100.615 24.3927 100.566 24.3815C100.615 24.389 100.645 24.3965 100.645 24.3965Z"
				fill={fillBodyLines}
			/>
			<path
				d="M100.597 22.9935C100.597 22.9935 100.615 23.0125 100.623 23.0201C100.615 23.0125 100.608 23.0049 100.597 22.9935Z"
				fill={fillBodyLines}
			/>
			<path
				d="M80.063 21.194H80.0554C82.6097 21.5689 85.02 21.9289 87.2295 22.2664C87.131 22.2514 87.0362 22.2364 86.9377 22.2214V13.402C78.945 9.20599 67.261 4.83002 51.3666 3.00388C16.743 -0.974609 17.8913 16.2218 17.8913 16.2218C19.1685 15.9031 20.9269 15.7156 23.0644 15.6331C23.0644 15.6331 23.8186 7.33486 33.0846 6.26992C42.3507 5.20499 53.4472 5.41873 61.421 6.91113C69.3947 8.40354 80.063 12.8807 80.063 12.8807V21.194Z"
				fill={fillBodyLines}
			/>
			<path
				d="M93.3131 23.2073C92.8353 23.1323 92.3386 23.0536 91.8229 22.9748C92.3386 23.0573 92.8353 23.1323 93.3131 23.2073Z"
				fill={fillBodyLines}
			/>
			<path
				d="M90.8798 22.829C90.6561 22.7952 90.4324 22.7577 90.2011 22.724C90.4286 22.7577 90.6561 22.7952 90.8798 22.829Z"
				fill={fillBodyLines}
			/>
			<path
				d="M91.6824 22.9523C91.4625 22.9185 91.2388 22.8848 91.0113 22.8473C91.2388 22.881 91.4625 22.9185 91.6824 22.9523Z"
				fill={fillBodyLines}
			/>
			<path
				d="M94.0178 23.3203C93.8244 23.2903 93.631 23.2603 93.43 23.2266C93.631 23.2566 93.8244 23.2903 94.0178 23.3203Z"
				fill={fillBodyLines}
			/>
			<path
				d="M86.9413 13.4015C87.2333 13.5552 87.5214 13.709 87.802 13.859C87.5214 13.7052 87.2333 13.5552 86.9413 13.4015Z"
				fill={fillBodyLines}
			/>
			<path
				d="M100.535 22.9306C100.554 22.9532 100.573 22.9683 100.588 22.9872C100.573 22.9721 100.558 22.9532 100.535 22.9306Z"
				fill={fillBodyLines}
			/>
			<path
				d="M87.8088 13.8627C94.627 17.5525 98.5762 21.0285 100.016 22.4122C98.58 21.0322 94.627 17.5562 87.8088 13.8627Z"
				fill={fillBodyLines}
			/>
			<path
				d="M100.32 22.7085C100.282 22.671 100.236 22.626 100.191 22.581C100.236 22.626 100.278 22.6672 100.32 22.7085Z"
				fill={fillBodyLines}
			/>
			<path
				d="M100.448 22.8364C100.474 22.8662 100.501 22.8886 100.523 22.9147C100.501 22.8923 100.478 22.8662 100.448 22.8364Z"
				fill={fillBodyLines}
			/>
			<path
				d="M99.7281 24.2424C99.6485 24.2274 99.5613 24.2162 99.4703 24.2012C99.5613 24.2162 99.6447 24.2312 99.7281 24.2424Z"
				fill={fillBodyLines}
			/>
			<path
				d="M99.9369 24.2769C99.899 24.2694 99.8611 24.2656 99.8232 24.2581C99.8649 24.2656 99.899 24.2694 99.9369 24.2769Z"
				fill={fillBodyLines}
			/>
			<path
				d="M100.41 24.355C100.384 24.3513 100.353 24.3475 100.323 24.34C100.353 24.3438 100.384 24.3513 100.41 24.355Z"
				fill={fillBodyLines}
			/>
			<path
				d="M99.0658 24.1334C98.9672 24.1184 98.8648 24.0997 98.7586 24.0847C98.8648 24.1035 98.9672 24.1184 99.0658 24.1334Z"
				fill={fillBodyLines}
			/>
			<path
				d="M96.0675 23.6465C95.8931 23.6165 95.7111 23.5902 95.5253 23.5602C95.7111 23.5902 95.8893 23.6202 96.0675 23.6465Z"
				fill={fillBodyLines}
			/>
			<path
				d="M96.6397 23.74C96.4843 23.7137 96.325 23.6912 96.162 23.665C96.325 23.6912 96.4843 23.7175 96.6397 23.74Z"
				fill={fillBodyLines}
			/>
			<path
				d="M98.6068 24.0583C98.512 24.0433 98.4096 24.0246 98.3072 24.0096C98.4096 24.0246 98.5082 24.0433 98.6068 24.0583Z"
				fill={fillBodyLines}
			/>
			<path
				d="M98.2312 23.9985C98.1099 23.9798 97.9848 23.9573 97.8558 23.9385C97.9848 23.961 98.1099 23.9798 98.2312 23.9985Z"
				fill={fillBodyLines}
			/>
			<path
				d="M97.182 23.8263C97.0379 23.8038 96.89 23.7776 96.7384 23.7551C96.89 23.7813 97.0379 23.8038 97.182 23.8263Z"
				fill={fillBodyLines}
			/>
			<path
				d="M97.7534 23.9197C97.6018 23.8935 97.4425 23.871 97.2794 23.8447C97.4425 23.871 97.6018 23.8972 97.7534 23.9197Z"
				fill={fillBodyLines}
			/>
			<path
				d="M53.1661 3.22091L55.1066 17.8675L50.2213 17.3125V2.87593L53.1661 3.22091Z"
				fill={fillBodyLines}
			/>
			<path
				d="M17.8952 16.2222C17.8952 16.2222 22.0564 42.3768 57.8321 42.3768L84.933 43.6217C85.4787 43.6479 86.0093 43.4267 86.3655 43.018C87.7867 41.3793 90.9664 36.1784 90.4244 22.758"
				stroke={fillBodyLines}
				strokeMiterlimit="10"
			/>
			<path d="M52.6926 17.5903L52.6665 42.1812" stroke={fillBodyLines} strokeMiterlimit="10" />
			<path
				d="M109.48 29.7242C112.437 33.7214 114.04 34.9438 114.733 36.8562C113.521 26.7169 103.659 22.6184 103.659 22.6184C104.629 23.627 106.581 25.8057 109.48 29.7242Z"
				fill={fillFrontLights}
			/>
			<path
				d="M108.928 51.4987C112.558 47.9089 112.558 42.0887 108.928 38.4989C105.298 34.9091 99.4125 34.9091 95.7825 38.4989C92.1524 42.0887 92.1524 47.9089 95.7825 51.4987C99.4125 55.0884 105.298 55.0884 108.928 51.4987Z"
				fill={fillDarkLines}
			/>
			<path
				d="M102.354 51.7065C106.098 51.7065 109.134 48.7031 109.134 44.9982C109.134 41.2933 106.098 38.2899 102.354 38.2899C98.6091 38.2899 95.5735 41.2933 95.5735 44.9982C95.5735 48.7031 98.6091 51.7065 102.354 51.7065Z"
				stroke={fillWheelRims}
				strokeMiterlimit="10"
			/>
			<path d="M103.431 38.2897H101.275V42.7857H103.431V38.2897Z" fill={fillWheelRims} />
			<path d="M103.431 47.0936H101.275V51.5896H103.431V47.0936Z" fill={fillWheelRims} />
			<path
				d="M98.3622 39.4838L96.8373 40.9925L100.051 44.1716L101.575 42.6629L98.3622 39.4838Z"
				fill={fillWheelRims}
			/>
			<path
				d="M104.655 45.706L103.13 47.2147L106.344 50.3939L107.868 48.8852L104.655 45.706Z"
				fill={fillWheelRims}
			/>
			<path d="M100.176 43.8733H95.6316V46.0069H100.176V43.8733Z" fill={fillWheelRims} />
			<path d="M109.074 43.8733H104.53V46.0069H109.074V43.8733Z" fill={fillWheelRims} />
			<path
				d="M100.051 45.7077L96.8373 48.8868L98.3622 50.3955L101.575 47.2164L100.051 45.7077Z"
				fill={fillWheelRims}
			/>
			<path
				d="M106.345 39.4845L103.132 42.6637L104.657 44.1724L107.87 40.9932L106.345 39.4845Z"
				fill={fillWheelRims}
			/>
			<path
				d="M104.589 44.9983C104.589 46.2207 103.589 47.2106 102.353 47.2106C101.117 47.2106 100.117 46.2207 100.117 44.9983C100.117 43.7759 101.117 42.7859 102.353 42.7859C103.589 42.7859 104.589 43.7759 104.589 44.9983Z"
				stroke={fillWheelRims}
				strokeMiterlimit="10"
			/>
			<path
				d="M103.188 44.998C103.188 45.4555 102.813 45.8267 102.35 45.8267C101.888 45.8267 101.512 45.4555 101.512 44.998C101.512 44.5406 101.888 44.1693 102.35 44.1693C102.813 44.1693 103.188 44.5406 103.188 44.998Z"
				fill={fillWheelRims}
			/>
			<path
				d="M4.85769 16.8557L6.21831 16.2145C7.44627 15.6333 8.51126 14.7596 9.30716 13.6685L11.7593 10.3124C13.0479 8.54628 13.5558 6.34143 13.1692 4.19656C8.49989 8.21631 6.07429 12.7873 4.85769 16.8557Z"
				fill={fillBackLights}
			/>
			<path
				d="M55.8425 20.7304L60.0583 21.2521C60.5095 21.308 60.8287 21.7141 60.7723 22.1605L60.7445 22.3801C60.6881 22.8266 60.2776 23.1423 59.8264 23.0865L55.6106 22.5647C55.1593 22.5089 54.8402 22.1028 54.8966 21.6563L54.9244 21.4368C54.9808 20.9903 55.3912 20.6745 55.8425 20.7304Z"
				fill={fillBodyLines}
			/>
			<path
				d="M30.1635 18.0447L25.9206 17.9172C25.4666 17.9035 25.0874 18.2566 25.0736 18.7058L25.0668 18.9269C25.053 19.3761 25.4099 19.7513 25.8639 19.765L30.1067 19.8925C30.5607 19.9061 30.94 19.5531 30.9538 19.1039L30.9605 18.8828C30.9743 18.4336 30.6175 18.0584 30.1635 18.0447Z"
				fill={fillBodyLines}
			/>
			<g style={{ mixBlendMode: 'screen' }} opacity="0.5">
				<path
					d="M1.94785 41.5215C1.94785 41.5215 2.35338 35.8444 7.79583 32.7846C8.89114 32.1696 9.26256 30.7822 8.66374 29.6873C7.93227 28.3411 6.09033 28.0374 4.98743 29.1061C2.97115 31.0559 0.625135 34.8657 1.94785 41.5253V41.5215Z"
					fill={fillBody}
				/>
			</g>
			<g style={{ mixBlendMode: 'screen' }} opacity="0.5">
				<path
					d="M4.47892 27.9139C4.47892 27.9139 6.47247 26.3165 8.57592 25.7278C8.57592 25.7278 7.44271 20.1482 8.03774 16.2222C8.03774 16.2222 3.40256 18.907 4.48271 27.9139H4.47892Z"
					fill={fillBody}
				/>
			</g>
			<g style={{ mixBlendMode: 'screen' }} opacity="0.5">
				<path
					d="M9.32934 14.3213C9.32934 14.3213 14.0176 10.2603 13.8357 4.19695C13.8357 4.19695 21.6999 2.00709 24.8608 1.79335C24.8608 1.79335 16.9207 3.49949 8.94655 17.1449L9.32555 14.3213H9.32934Z"
					fill={fillBody}
				/>
			</g>
			<g style={{ mixBlendMode: 'multiply' }} opacity="0.5">
				<path
					d="M29.8101 42.4179C28.0176 41.9454 26.4031 41.1505 24.728 40.3855C25.0388 41.4167 25.2093 42.5079 25.2093 43.6366C25.2093 44.2253 25.1639 44.8027 25.0767 45.3689L91.5687 47.0976C91.3906 46.5464 91.2504 45.9727 91.1594 45.3877C73.2147 45.084 55.2662 44.7052 37.3632 43.4641C34.8316 43.2878 32.2735 43.0703 29.8139 42.4216L29.8101 42.4179Z"
					fill={fillBody}
				/>
			</g>
			<path
				d="M20.3512 51.4979C23.9812 47.9081 23.9812 42.0879 20.3512 38.4981C16.7211 34.9084 10.8357 34.9084 7.20565 38.4981C3.57562 42.0879 3.57562 47.9081 7.20565 51.4979C10.8357 55.0876 16.7211 55.0877 20.3512 51.4979Z"
				fill={fillDarkLines}
			/>
			<path
				d="M13.7725 51.7057C17.5172 51.7057 20.5528 48.7023 20.5528 44.9974C20.5528 41.2925 17.5172 38.2891 13.7725 38.2891C10.0278 38.2891 6.99217 41.2925 6.99217 44.9974C6.99217 48.7023 10.0278 51.7057 13.7725 51.7057Z"
				stroke={fillWheelRims}
				strokeMiterlimit="10"
			/>
			<path d="M14.8531 38.2889H12.6966V42.7849H14.8531V38.2889Z" fill={fillWheelRims} />
			<path d="M14.8531 47.0928H12.6966V51.5888H14.8531V47.0928Z" fill={fillWheelRims} />
			<path
				d="M9.78401 39.4803L8.25912 40.989L11.4724 44.1681L12.9973 42.6594L9.78401 39.4803Z"
				fill={fillWheelRims}
			/>
			<path
				d="M16.0771 45.7079L14.5522 47.2166L17.7654 50.3959L19.2902 48.8871L16.0771 45.7079Z"
				fill={fillWheelRims}
			/>
			<path d="M11.5976 43.8725H7.05335V46.0061H11.5976V43.8725Z" fill={fillWheelRims} />
			<path d="M20.4962 43.8725H15.952V46.0061H20.4962V43.8725Z" fill={fillWheelRims} />
			<path
				d="M11.4724 45.7078L8.25912 48.8869L9.78401 50.3956L12.9973 47.2165L11.4724 45.7078Z"
				fill={fillWheelRims}
			/>
			<path
				d="M17.7669 39.48L14.5537 42.6592L16.0786 44.168L19.2917 40.9887L17.7669 39.48Z"
				fill={fillWheelRims}
			/>
			<path
				d="M13.7717 47.2098C15.0067 47.2098 16.0078 46.2193 16.0078 44.9975C16.0078 43.7756 15.0067 42.7851 13.7717 42.7851C12.5367 42.7851 11.5356 43.7756 11.5356 44.9975C11.5356 46.2193 12.5367 47.2098 13.7717 47.2098Z"
				stroke={fillWheelRims}
				strokeMiterlimit="10"
			/>
			<path
				d="M14.6099 44.9972C14.6099 45.4547 14.2345 45.8259 13.7719 45.8259C13.3093 45.8259 12.9339 45.4547 12.9339 44.9972C12.9339 44.5398 13.3093 44.1685 13.7719 44.1685C14.2345 44.1685 14.6099 44.5398 14.6099 44.9972Z"
				fill={fillWheelRims}
			/>
			<path
				d="M20.3626 9.51329C17.6717 12.8993 17.8954 16.2216 17.8954 16.2216C18.9982 15.9479 20.4612 15.7679 22.2122 15.6741V12.6181C22.2122 11.2832 21.4655 10.1208 20.3626 9.51329Z"
				fill={fillDarkLines}
			/>
			<g style={{ mixBlendMode: 'screen' }} opacity="0.7">
				<path
					d="M43.8969 0.798676C47.0728 0.907419 50.241 1.21115 53.3942 1.60862C56.5473 2.00985 59.6814 2.55731 62.7966 3.19477C65.9043 3.86973 68.9816 4.67968 72.0134 5.64336C75.0339 6.6408 77.9862 7.82947 80.8171 9.25813C79.3505 8.65442 77.9217 7.96821 76.4172 7.45825C74.9467 6.87328 73.427 6.40831 71.9225 5.9171C68.8868 5.0134 65.8209 4.20346 62.7171 3.5585C59.617 2.89479 56.4904 2.35483 53.3525 1.89361C51.7835 1.66112 50.2107 1.45488 48.6342 1.2749C47.0576 1.07991 45.4773 0.929917 43.8969 0.798676Z"
					fill={fillBody}
				/>
			</g>
			<g style={{ mixBlendMode: 'multiply' }}>
				<path
					d="M10.808 11.6097C10.5238 11.7597 10.2243 11.8909 9.90977 11.9884C9.12903 12.2321 8.29523 12.3184 7.48417 12.2284C7.41974 12.2209 7.15065 12.1534 6.82092 12.0634C5.953 13.6945 5.31629 15.3144 4.85769 16.8556L6.21831 16.2144C7.44627 15.6332 8.51126 14.7595 9.30716 13.6683L10.8118 11.6097H10.808Z"
					fill={fillBackLights}
				/>
			</g>
			<g style={{ mixBlendMode: 'multiply' }}>
				<path
					d="M111.871 32.769C113.353 34.5576 114.259 35.5438 114.732 36.8562C114.585 35.6151 114.304 34.4676 113.933 33.4027C113.876 33.4027 113.819 33.3952 113.766 33.3915C113.076 33.3352 112.443 33.1102 111.871 32.7652V32.769Z"
					fill={fillFrontLightsBottom}
				/>
			</g>
			<g style={{ mixBlendMode: 'multiply' }} opacity="0.7">
				<path
					d="M79.0846 23.3202C79.0846 23.3202 80.9228 24.6439 82.4388 23.6614C83.9548 22.679 82.7799 19.368 82.7799 19.368L80.4187 20.4554L79.0846 23.3202Z"
					fill={fillBody}
				/>
			</g>
			<path
				d="M79.0846 17.5903H80.4528C81.7376 17.5903 82.7799 18.6215 82.7799 19.8927V21.0139C82.7799 22.285 81.7376 23.3162 80.4528 23.3162H79.0846V17.5866V17.5903Z"
				fill={fillBody}
			/>
		</svg>
	)
}

const MemoisedEvCarImage = memo(EvCarImage)

export default MemoisedEvCarImage
