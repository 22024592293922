import dayjs from 'dayjs'
import { ReactNode, useCallback, useEffect, useMemo, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import Button from 'src/_shared/components/Button'
import Modal from 'src/_shared/components/Modal'
import ModalCard from 'src/_shared/components/Modal/components/ModalCard'
import PoweredByFooter from 'src/_shared/components/PoweredByFooter'
import Spinner from 'src/_shared/components/Spinner'
import BatteryGradientIcon from 'src/_shared/components/_icons/BatteryGradientIcon'
import CheckCircleFilledIcon from 'src/_shared/components/_icons/CheckCircleFilledIcon'
import CheckCircleGradientIcon from 'src/_shared/components/_icons/CheckCircleGradientIcon'
import HourglassFilledIcon from 'src/_shared/components/_icons/HourglassFilledIcon'
import HourglassGradientIcon from 'src/_shared/components/_icons/HourglassGradientIcon'
import HourglassIcon from 'src/_shared/components/_icons/HourglassIcon'
import { BRAND, TIME_FORMAT } from 'src/_shared/constants/env'
import { Brand } from 'src/_shared/enums/env'
import {
	OmniConnectorStatus,
	OmniSessionChargingPeriodDimensionType,
	OmniSessionStage,
	OmniSessionStatus,
	OmniSessionStopReason
} from 'src/_shared/enums/omni'
import {
	OmniSessionChargingPeriod,
	OmniSessionChargingPeriodDimension
} from 'src/_shared/types/omni'
import { classNames } from 'src/_shared/utils/elements'
import { formatCurrencyValue } from 'src/_shared/utils/string'

import { PERIOD_TIME_FORMAT } from '../constants'
import { ChargerScreenViewItemKey } from '../enums'
import useChargerDebugLogging from '../hooks/useChargerDebugLogging'
import { useChargerDetails } from '../hooks/useChargerDetails'
import { useChargerSessionDetails } from '../hooks/useChargerSessionDetails'
import { ChargerScreenCommonViewProps } from '../types'
import { getLatestChargingPeriodDimension, getSessionStateOfCharge } from '../utils'
import StateOfChargeCircle from './StateOfChargeCircle'

type PostChargingViewProps = ChargerScreenCommonViewProps

interface PostChargingViewRenders {
	icon: ReactNode
	headerText: ReactNode
	chargingEndTime: ReactNode
	descriptionText?: ReactNode
	footerText?: ReactNode
}

enum PostChargingViewState {
	Blackout, // State where Idling Fees will not be incurred
	Default,
	Grace, // State before Idling
	Idling
}

const PostChargingView = ({
	routeParams,
	onNext: handleNext
}: PostChargingViewProps): JSX.Element => {
	const [isStopReasonAcknowledged, setIsStopReasonAcknowledged] = useState<boolean>(false)

	const intl = useIntl()

	const { connector } = useChargerDetails(routeParams)

	const { session, isUserChargingSession, chargerSessionDetailsQueryStatus } =
		useChargerSessionDetails(routeParams)

	const isStopReasonModalOpen =
		!isStopReasonAcknowledged &&
		!!session?.stop_reason &&
		[
			OmniSessionStopReason.MaximumPriceReached,
			OmniSessionStopReason.TrickleCharge,
			OmniSessionStopReason.VehicleManualStop
		].includes(session.stop_reason)

	/**
	 * This should be the first charging period that contains a `PARKING_TIME` dimension.
	 */
	const firstPostChargingPeriod = useMemo((): OmniSessionChargingPeriod | null => {
		if (session?.charging_periods && session.charging_periods.length > 0) {
			return (
				session.charging_periods.find((chargingPeriod): boolean => {
					const hasParkingDimension =
						chargingPeriod.dimensions?.some((dimension): boolean => {
							return dimension.type === OmniSessionChargingPeriodDimensionType.ParkingTime
						}) ?? false
					return hasParkingDimension
				}) ?? null
			)
		}
		return null
	}, [session?.charging_periods])

	/**
	 * This should be the first charging period that contains a `PARKING_TIME` dimension at the `PARKING_REGULAR` stage.
	 */
	const firstIdlingChargingPeriod = useMemo((): OmniSessionChargingPeriod | null => {
		if (session?.charging_periods && session.charging_periods.length > 0) {
			return (
				session.charging_periods.find((chargingPeriod): boolean => {
					const hasParkingDimensionAndIdlingStage =
						chargingPeriod.dimensions?.some(
							(dimension: OmniSessionChargingPeriodDimension): boolean => {
								return (
									dimension.type === OmniSessionChargingPeriodDimensionType.ParkingTime &&
									dimension.session_stage === OmniSessionStage.ParkingRegular
								)
							}
						) ?? false
					return hasParkingDimensionAndIdlingStage
				}) ?? null
			)
		}
		return null
	}, [session?.charging_periods])

	const postChargingViewState = useMemo((): PostChargingViewState | null => {
		const parkingTimeDimension = getLatestChargingPeriodDimension(
			session?.charging_periods,
			OmniSessionChargingPeriodDimensionType.ParkingTime
		)
		if (parkingTimeDimension) {
			switch (parkingTimeDimension.session_stage) {
				case OmniSessionStage.ParkingBlackout:
					return PostChargingViewState.Blackout
				case OmniSessionStage.ParkingGrace:
					return PostChargingViewState.Grace
				case OmniSessionStage.ParkingRegular:
					return PostChargingViewState.Idling
				case OmniSessionStage.NotSpecified:
					return PostChargingViewState.Default
			}
		}
		// Account for chargers that do not have any idling fees.
		else if (
			chargerSessionDetailsQueryStatus === 'success' &&
			!session?.tariff?.price_per_min_parked?.incl_vat
		) {
			return PostChargingViewState.Default
		}
		return null
	}, [
		chargerSessionDetailsQueryStatus,
		session?.charging_periods,
		session?.tariff?.price_per_min_parked?.incl_vat
	])

	const { icon, headerText, chargingEndTime, descriptionText, footerText } =
		useMemo((): PostChargingViewRenders => {
			// For View States that are not Idling.
			const getPostChargingIcon = (defaultIcon: JSX.Element): JSX.Element => {
				switch (BRAND) {
					case Brand.Evme: {
						const icon = ((): JSX.Element => {
							switch (postChargingViewState) {
								case PostChargingViewState.Blackout:
								case PostChargingViewState.Grace:
									return (
										<BatteryGradientIcon
											className="w-24"
											lightningFill="#434E64"
											gradientFrom="#CBFF31"
											gradientTo="#80FFCA"
										/>
									)
								default:
									return (
										<CheckCircleGradientIcon
											className="my-4 h-24 w-24"
											gradientFrom="#CBFF31"
											gradientTo="#80FFCA"
										/>
									)
							}
						})()
						return icon
					}
					case Brand.Kineta: {
						const stateOfChargeDimension = getSessionStateOfCharge(session)
						const percentage =
							typeof stateOfChargeDimension?.volume === 'number'
								? stateOfChargeDimension.volume
								: null
						return (
							<StateOfChargeCircle
								variant={percentage !== null ? 'progress' : 'active'}
								centerRender={
									percentage !== null && (
										<>
											<p className="text-[24px] font-semibold leading-7 text-white">
												{percentage}%
											</p>
											<p className="caption-2-medium mb-1 leading-none text-white">
												<FormattedMessage
													id="PostChargingView.TitleStateOfCharge"
													defaultMessage="State of Charge"
												/>
											</p>
										</>
									)
								}
								percentage={percentage}
								isCharging={true}
							/>
						)
					}
					default:
						return defaultIcon
				}
			}

			const chargingEndTime = firstPostChargingPeriod?.start_date_time
				? dayjs(firstPostChargingPeriod.start_date_time).format(TIME_FORMAT)
				: null

			const sessionCompleteHeaderText = intl.formatMessage({
				id: 'PostChargingView.HeaderChargingStopped',
				defaultMessage: 'Charging Stopped'
			})

			switch (postChargingViewState) {
				case PostChargingViewState.Blackout: {
					const blackoutEndTime = session?.tariff?.idle_blackout_period?.end_time_of_day
						? dayjs(session.tariff.idle_blackout_period.end_time_of_day, PERIOD_TIME_FORMAT).format(
								TIME_FORMAT
							)
						: '-'
					return {
						icon: getPostChargingIcon(<BatteryGradientIcon className="w-24" />),
						headerText: sessionCompleteHeaderText,
						chargingEndTime,
						descriptionText: (
							<span className="body-2-medium text-error-300">
								<FormattedMessage
									id="PostChargingView.DescriptionBlackoutGraceLeaveCharger"
									defaultMessage="Please move your EV from the charging lot by {idleFeesStartTime} to avoid idling fees and allow other drivers to use the charging lot."
									values={{
										idleFeesStartTime: blackoutEndTime
									}}
								/>
							</span>
						),
						footerText: null
					}
				}
				case PostChargingViewState.Grace: {
					const graceEndTime =
						firstPostChargingPeriod?.start_date_time &&
						session?.tariff?.grace_period_seconds !== undefined
							? dayjs(firstPostChargingPeriod.start_date_time)
									.add(session.tariff.grace_period_seconds, 'seconds')
									.format(TIME_FORMAT)
							: '-'
					return {
						icon: getPostChargingIcon(<BatteryGradientIcon className="w-24" />),
						headerText: sessionCompleteHeaderText,
						chargingEndTime,
						descriptionText: (
							<span className="body-2-medium text-error-300">
								<FormattedMessage
									id="PostChargingView.DescriptionBlackoutGraceLeaveCharger"
									defaultMessage="Please move your EV from the charging lot by {idleFeesStartTime} to avoid idling fees and allow other drivers to use the charging lot."
									values={{
										idleFeesStartTime: graceEndTime
									}}
								/>
							</span>
						),
						footerText: null
					}
				}
				case PostChargingViewState.Idling: {
					const idleCostRate = session?.tariff?.price_per_min_parked?.incl_vat
					return {
						icon: ((): JSX.Element => {
							switch (BRAND) {
								case Brand.Evme: {
									return (
										<HourglassGradientIcon
											className="w-24"
											coverFill="#434E64"
											gradientFrom="#CBFF31"
											gradientTo="#80FFCA"
										/>
									)
								}
								case Brand.Kineta: {
									return (
										<StateOfChargeCircle
											variant="warning"
											centerRender={<HourglassFilledIcon className="h-9 w-9 text-white" />}
										/>
									)
								}

								default:
									return <HourglassIcon className="w-24" />
							}
						})(),
						headerText: intl.formatMessage({
							id: 'PostChargingView.DescriptionIncurringIdlingFees',
							defaultMessage: 'You Are Now Incurring Idling Fees'
						}),
						chargingEndTime,
						descriptionText: intl.formatMessage(
							{
								id: 'PostChargingView.DescriptionIdlingFeesLeaveCharger',
								defaultMessage:
									'You are currently incurring idling fees of {pricePerMin}/min. Please move your EV from the charging lot.'
							},
							{
								pricePerMin: formatCurrencyValue(idleCostRate, session?.tariff?.currency)
							}
						),
						footerText: (
							<span className="text-typography-primary">
								<FormattedMessage
									id="PostChargingView.DescriptionPrefixIdleFeesStartedAt"
									defaultMessage="<b>Idling fees started at:</b> <h3>{idleFeesStartTime}</h3>"
									values={{
										b: (chunks): JSX.Element => <span className="body-2-medium">{chunks}</span>,
										h3: (chunks): JSX.Element => <h3 className="text-center">{chunks}</h3>,
										idleFeesStartTime: firstIdlingChargingPeriod?.start_date_time
											? dayjs(firstIdlingChargingPeriod.start_date_time).format(TIME_FORMAT)
											: '-'
									}}
								/>
							</span>
						)
					}
				}
				case PostChargingViewState.Default:
				default: {
					const sessionEndTime =
						// Check that Session is completed so that the correct `end_date_time` is shown.
						session?.status === OmniSessionStatus.Completed && session.end_date_time
							? dayjs(session.end_date_time).format(TIME_FORMAT)
							: '-'
					return {
						icon: getPostChargingIcon(
							<CheckCircleFilledIcon className="my-4 h-24 w-24 text-success-400" />
						),
						headerText: sessionCompleteHeaderText,
						chargingEndTime: sessionEndTime,
						footerText: (
							<h1 className="text-center text-error-300">
								<FormattedMessage
									id="PostChargingView.DescriptionDefaultLeaveCharger"
									defaultMessage="Please unplug your charger and remove your EV."
								/>
							</h1>
						)
					}
				}
			}
		}, [
			firstPostChargingPeriod?.start_date_time,
			intl,
			firstIdlingChargingPeriod?.start_date_time,
			postChargingViewState,
			session
		])

	const handleStopReasonModalClose = useCallback((): void => {
		setIsStopReasonAcknowledged(true)
	}, [])

	/**
	 * Move to Receipt View when session has been completed.
	 */
	useEffect((): void => {
		if (
			isUserChargingSession &&
			!isStopReasonModalOpen &&
			session?.status === OmniSessionStatus.Completed &&
			!!connector?.status &&
			[
				OmniConnectorStatus.Available,
				OmniConnectorStatus.Preparing // Account for City Energy, MNL Asia, and Virta.
				// Note: Unable to account for `OUTOFORDER` or `UNKNOWN`.
			].includes(connector.status)
		) {
			handleNext?.()
		}
	}, [connector?.status, isStopReasonModalOpen, isUserChargingSession, session?.status, handleNext])

	useChargerDebugLogging({
		connector,
		isUserChargingSession,
		session,
		viewItemKey: ChargerScreenViewItemKey.PostChargingView
	})

	return (
		<div
			className={classNames(
				'flex flex-grow flex-col items-center',
				postChargingViewState === null ? 'justify-center pb-6' : null
			)}
		>
			{postChargingViewState !== null && !!chargingEndTime ? (
				<>
					{/* Header */}
					<div className="flex w-full flex-col items-center justify-center space-y-5 py-3">
						<div className="flex items-center">{icon}</div>
						<h1 className="text-center text-typography-primary">{headerText}</h1>
					</div>
					{/* Content */}
					<div className={classNames('flex flex-col items-center', footerText ? 'mb-8' : null)}>
						<p className="body-2-normal mb-5 text-center text-typography-tertiary">
							<FormattedMessage
								id="PostChargingView.SubHeaderPrefixStoppedChargingAt"
								defaultMessage="EV stopped charging at {chargingEndTime}"
								values={{ chargingEndTime }}
							/>
						</p>
						<div className="mb-5 space-y-1 py-3">
							<p className="body-2-normal text-center text-typography-tertiary">
								<FormattedMessage
									id="PostChargingView.SubHeaderTotalCharge"
									defaultMessage="Energy Delivered"
								/>
							</p>
							<div className="flex flex-row items-end justify-center text-typography-primary">
								<h5 className="mr-1.5">{session?.kwh?.toFixed(3) ?? '-'}</h5>
								<h2 className="leading-9">
									<FormattedMessage
										id="PostChargingView.SubHeaderEnergyDeliveredValueSuffix"
										defaultMessage="kWh"
									/>
								</h2>
							</div>
						</div>
						{descriptionText && (
							<p className="body-2-normal text-center text-typography-tertiary">
								{descriptionText}
							</p>
						)}
					</div>
					{/* Footer */}
					{footerText && <div className="mb-8 flex flex-col items-center">{footerText}</div>}
					<PoweredByFooter className="mt-auto pb-6" />
				</>
			) : (
				<Spinner />
			)}
			{/* Stop Reason Modal */}
			<Modal open={isStopReasonModalOpen}>
				<ModalCard className="flex max-w-62 flex-col items-center">
					<div className="mb-8 flex flex-col items-center">
						<h1 className="mb-2 text-center">
							<FormattedMessage
								id="PostChargingView.ModalTitleChargingStopped"
								defaultMessage="Charging Stopped"
							/>
						</h1>
						<p className="body-2-light text-center">
							{((): string | null => {
								switch (session?.stop_reason) {
									case OmniSessionStopReason.MaximumPriceReached:
										return intl.formatMessage({
											id: 'PostChargingView.ModalDescriptionChargingStoppedInsufficientFunds',
											defaultMessage:
												'The charging has been automatically stopped due to insufficient balance.'
										})
									case OmniSessionStopReason.TrickleCharge:
										return intl.formatMessage({
											id: 'PostChargingView.ModalDescriptionChargingStoppedTrickleCharge',
											defaultMessage:
												'The charging has been automatically stopped due to a fully charged battery.'
										})
									case OmniSessionStopReason.VehicleManualStop:
										return intl.formatMessage({
											id: 'PostChargingView.ModalDescriptionChargingStoppedVehicleManualStop',
											defaultMessage: 'The charging has been automatically stopped by your EV.'
										})
									default:
										return null
								}
							})()}
						</p>
					</div>
					<Button
						data-testid="cs-pcv-btn-close"
						className="w-full"
						onClick={handleStopReasonModalClose}
					>
						<FormattedMessage id="PostChargingView.ModalButtonTextClose" defaultMessage="Close" />
					</Button>
				</ModalCard>
			</Modal>
		</div>
	)
}

export default PostChargingView
