import { QrScanner } from '@yudiel/react-qr-scanner'
import { useMemo } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { ScreenRoutePath, useQueryParams, useRouterNavigate } from 'src/App/router/hooks'
import Button from 'src/_shared/components/Button'
import Modal from 'src/_shared/components/Modal'
import ModalCard from 'src/_shared/components/Modal/components/ModalCard'
import PoweredByFooter from 'src/_shared/components/PoweredByFooter'
import ScreenContainer from 'src/_shared/components/ScreenContainer'
import ErrorFilledIcon from 'src/_shared/components/_icons/ErrorFilledIcon'
import { useLocationsByQrIdentifierMutation } from 'src/_shared/mutations/locations'
import { getMatchingChargersByQrIdentifier } from 'src/_shared/utils/checkIn'

import { CheckInQrScannerScreenQueryParams } from './types'

// 0.125 Seconds
const SCAN_DELAY = 125

const CheckInQrScannerScreen = (): JSX.Element => {
	const [queryParams] = useQueryParams<CheckInQrScannerScreenQueryParams>()

	const intl = useIntl()

	const navigate = useRouterNavigate()

	const {
		data: locationsByQrIdentifierData,
		error: locationsByQrIdentifierError,
		isError: isLocationsByQrIdentifierError,
		isSuccess: isLocationsByQrIdentifierSuccess,
		isPending: isLocationsByQrIdentifierPending,
		mutateAsync: getLocationsByQrIdentifier,
		reset: resetLocationsByQrIdentifierMutation
	} = useLocationsByQrIdentifierMutation()

	const cpoEntityCodes = useMemo((): string[] => {
		if (queryParams.cpoEntityCodes) {
			return queryParams.cpoEntityCodes
				.split(',')
				.map((entityCode) => entityCode.trim())
				.filter(Boolean)
		}
		return []
	}, [queryParams.cpoEntityCodes])

	const errorMessage = useMemo((): { title: string; description: string } | null => {
		const { locations, message: dataErrorMessage } = locationsByQrIdentifierData?.data ?? {}
		// Handle 4XX/5XX status errors thrown with messages.
		const genericErrorTitle = intl.formatMessage({
			id: 'CheckInQrScannerScreen.ModalTitleOopsFailure',
			defaultMessage: 'Oops! Something went wrong'
		})
		if (isLocationsByQrIdentifierError) {
			const description = locationsByQrIdentifierError.response?.data.message
				? locationsByQrIdentifierError.response.data.message
				: intl.formatMessage({
						id: 'CheckInQrScannerScreen.ModalDescriptionOopsFailure',
						defaultMessage: 'Please try again later.'
					})
			return {
				title: genericErrorTitle,
				description
			}
		}
		// Handle 200 status with error message.
		else if (dataErrorMessage) {
			return {
				title: genericErrorTitle,
				description: dataErrorMessage
			}
		}
		// No locations found.
		else if (isLocationsByQrIdentifierSuccess && (!locations || locations.length === 0)) {
			return {
				title: intl.formatMessage({
					id: 'CheckInQrScannerScreen.ModalTitleInvalidQrCode',
					defaultMessage: 'QR Code Is Invalid'
				}),
				description: intl.formatMessage({
					id: 'CheckInQrScannerScreen.ModalDescriptionInvalidQrCode',
					defaultMessage: 'Please try scanning the QR Code again or enter the Connector ID instead.'
				})
			}
		}
		return null
	}, [
		intl,
		isLocationsByQrIdentifierError,
		isLocationsByQrIdentifierSuccess,
		locationsByQrIdentifierError,
		locationsByQrIdentifierData?.data
	])

	const handleLocationsByQrIdentifierModalClose = (): void => {
		resetLocationsByQrIdentifierMutation()
	}

	const handleDecode = (qrCodeStr: string): void => {
		if (!isLocationsByQrIdentifierPending && !errorMessage) {
			void getLocationsByQrIdentifier(
				{
					qrIdentifier: qrCodeStr,
					entityCodes: cpoEntityCodes.length > 0 ? cpoEntityCodes : undefined
				},
				{
					onSuccess: (response): void => {
						if (response.data.locations.length) {
							const location = response.data.locations[0]

							const { matchedConnectors } = getMatchingChargersByQrIdentifier(
								location.evses,
								qrCodeStr
							)

							// There are 2 or more connectors with the same `qr_identifier`
							// Note: This tends to occur for Reversharger chargers.
							if (matchedConnectors.length > 1 && location.uid) {
								navigate({
									pathname: [ScreenRoutePath.CheckInLocationScreen, location.uid],
									search: `qr_identifier=${qrCodeStr}`
								})
							} else {
								const evse = location.evses?.[0]
								const connector = evse?.connectors?.[0]
								if (location.entity_code && location.uid && evse?.uid && connector?.uid) {
									navigate([
										ScreenRoutePath.Charger,
										location.entity_code,
										location.uid,
										evse.uid,
										connector.uid
									])
								}
							}
						}
					}
				}
			)
		}
	}

	return (
		<ScreenContainer contentViewProps={{ className: 'px-5 py-6' }} hideBottomBar>
			{/* Content */}
			<div className="flex flex-grow flex-col items-center justify-center">
				<p className="body-2-semibold max-w-72 text-center text-typography-primary">
					<FormattedMessage
						id="CheckInQrScannerScreen.DescriptionInsertConnectorScanQrCode"
						defaultMessage="Insert the charging connector into your EV & scan the QR code"
					/>
				</p>
				<div className="my-12 w-60">
					<QrScanner
						data-testid="ciqss-qr-scanner"
						audio={false}
						viewFinder={
							() => null // Hides the default view finder border.
						}
						scanDelay={SCAN_DELAY}
						onDecode={handleDecode}
						videoStyle={{ borderRadius: 24 }}
					/>
				</div>
				<p className="body-2-semibold mb-20 max-w-72 text-center text-typography-primary">
					<FormattedMessage
						id="CheckInQrScannerScreen.DescriptionAlignScannerQrCode"
						defaultMessage="Please align scanner to QR code"
					/>
				</p>
			</div>
			{/* Footer */}
			<PoweredByFooter />
			{/* Error Modal */}
			<Modal open={!!errorMessage} onClose={handleLocationsByQrIdentifierModalClose}>
				<ModalCard className="flex flex-col items-center">
					<div className="mb-8 flex flex-col items-center">
						<ErrorFilledIcon
							data-testid="ciqss-icon-error"
							className="mb-4 h-12 w-12 text-error-300"
						/>
						<h1 data-testid="ciqss-label-error" className="mb-2 text-center">
							{errorMessage?.title}
						</h1>
						<p data-testid="ciqss-desc-error" className="body-2-light text-center">
							{errorMessage?.description}
						</p>
					</div>
					<Button
						data-testid="ciqss-btn-close"
						className="w-full"
						onClick={handleLocationsByQrIdentifierModalClose}
					>
						<FormattedMessage
							id="CheckInQrScannerScreen.ModalButtonTextClose"
							defaultMessage="Close"
						/>
					</Button>
				</ModalCard>
			</Modal>
		</ScreenContainer>
	)
}

export default CheckInQrScannerScreen
