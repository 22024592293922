import { ReactNode, useMemo } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import PriceModifierTag from 'src/_shared/components/PriceModifierTag'
import Tag from 'src/_shared/components/Tag'
import InformationCircleFilledIcon from 'src/_shared/components/_icons/InformationCircleFilledIcon'
import { APP_MODE, BRAND } from 'src/_shared/constants/env'
import { AppMode, Brand } from 'src/_shared/enums/env'
import {
	OmniSessionChargingPeriodDimensionType,
	OmniSessionStatus,
	OmniTariffPriceModifierType
} from 'src/_shared/enums/omni'
import { useStrapiContext } from 'src/_shared/hooks/useStrapiContext'
import { ChargerScreenUseChargerItemListsFeatureToggles } from 'src/_shared/queries/strapi'
import {
	OmniConnector,
	OmniSession,
	OmniSessionChargingPeriodDimension,
	OmniTariff
} from 'src/_shared/types/omni'
import { OmniLocation } from 'src/_shared/types/omni/location'
import { getTaxAcronym } from 'src/_shared/utils/currency'
import { classNames } from 'src/_shared/utils/elements'
import { formatCurrencyValue } from 'src/_shared/utils/string'

import { DetailsGridItem } from '../components/DetailsGrid'
import { DetailsRowItem } from '../components/DetailsRow'
import TimeElapsedText from '../components/TimeElapsedText'
import { TariffInformationItem } from '../types'
import { formatTimeToString, getTimeFromSeconds } from '../utils'

interface UseChargerItemListsArgs {
	location: OmniLocation | null
	connector: OmniConnector | null
	/**
	 * If `null`, then active tariff will be taken from `connector` instead.
	 */
	activeTariff: OmniTariff | null
	/**
	 * If `null`, then `rowsItemList` will be empty.
	 */
	session: OmniSession | null
	isAuthenticated: boolean
	isUserSubscribed: boolean
	toggleTariffModal?: () => void
}

interface ChargerItemLists {
	gridItemList: DetailsGridItem[]
	rowsItemList: DetailsRowItem[]
	tariffInformationItemList: TariffInformationItem[]
}

const getTariffLabelValue = (
	key:
		| 'min_price'
		| 'max_price'
		| 'price_per_kwh'
		| 'price_per_min'
		| 'price_per_min_parked'
		| 'price_flat',
	connector: OmniConnector | null,
	activeTariff: OmniTariff | null,
	isAuthenticated: boolean,
	isUserSubscribed: boolean,
	/**
	 * If `true`, then conditionally renders certain elements when screen width is too small.
	 */
	isGridItem: boolean
): JSX.Element | string => {
	if (connector) {
		const currencyCode = activeTariff?.currency ?? connector.original_tariff?.currency

		const originalCost = connector.original_tariff?.[key]?.incl_vat ?? 0

		// Handle Default and SSO Flows
		if ([AppMode.Default, AppMode.Sso].includes(APP_MODE)) {
			const formattedOriginalCost = formatCurrencyValue(originalCost, currencyCode)

			const slashedPriceElement = (
				<span
					className={classNames(
						'caption-3-normal mr-0.5 text-typography-tertiary line-through',
						isGridItem ? 'group-[.col-span-2]:inline 2xs:group-[.col-span-1]:hidden' : null
					)}
				>
					{key === 'price_per_min_parked' ? (
						<FormattedMessage
							id="useChargerItemLists.LabelValueCostPerMin"
							defaultMessage="{value}/min"
							values={{
								value: formattedOriginalCost
							}}
						/>
					) : (
						formattedOriginalCost
					)}
				</span>
			)

			// No cost for subscribed users.
			if (isUserSubscribed) {
				return (
					<span className="flex flex-row items-center">
						{slashedPriceElement}
						<Tag
							data-testid="cs-tlv-subcribed-tag"
							className="bg-orange-400 text-white"
							value={
								<FormattedMessage
									id="useChargerItemLists.LabelValueSubscribed"
									defaultMessage="Subscribed"
								/>
							}
						/>
					</span>
				)
			}

			// Check for price modifier(s) in active tariff.
			// Only idling fees should not be affected by price modifiers.
			if (activeTariff && key !== 'price_per_min_parked') {
				const activeCost = activeTariff[key]?.incl_vat ?? 0

				const discountPriceModifier = activeTariff.price_modifiers?.find(
					(priceModifier): boolean =>
						!!priceModifier.type &&
						[
							OmniTariffPriceModifierType.DiscountFlat,
							OmniTariffPriceModifierType.DiscountPercentage
						].includes(priceModifier.type)
				)

				// Discounted cost applied.
				// Only authenticated users can see the discount.
				if (isAuthenticated && discountPriceModifier && activeCost < originalCost) {
					return (
						<span className="flex flex-row items-center group-[.col-span-1]:absolute 2xs:group-[.col-span-1]:static">
							{slashedPriceElement}
							<span>{formatCurrencyValue(activeCost, activeTariff.currency)}</span>
							<PriceModifierTag
								dataTestIdPrefix="cs-tlv"
								className={classNames(isGridItem ? 'scale-80 ml-1' : 'scale-90')}
								currencyCode={currencyCode}
								priceModifierValue={discountPriceModifier.value}
								priceModifierType={discountPriceModifier.type}
							/>
						</span>
					)
				}
			}
		}
		const formattedOriginalCost = formatCurrencyValue(originalCost, activeTariff?.currency)
		if (key === 'price_per_min_parked') {
			return (
				<span className="text-nowrap">
					<FormattedMessage
						id="useChargerItemLists.LabelValueCostPerMin"
						defaultMessage="{value}/min"
						values={{
							value: formattedOriginalCost
						}}
					/>
				</span>
			)
		}
		return formattedOriginalCost
	}
	return '-'
}

export const useChargerItemLists = ({
	location,
	connector,
	activeTariff,
	session,
	isAuthenticated,
	isUserSubscribed,
	toggleTariffModal
}: UseChargerItemListsArgs): ChargerItemLists => {
	const intl = useIntl()

	const { getFeatureToggles } = useStrapiContext()

	const { hideTaxIndicators } = useMemo((): ChargerScreenUseChargerItemListsFeatureToggles => {
		return getFeatureToggles('chargerScreen')?.useChargerItemLists ?? {}
	}, [getFeatureToggles])

	/**
	 * Priority Order:
	 * 1. `activeTariff`: Used in `PreChargingView`.
	 * 2. `session.tariff`: Used in `ChargingView`.
	 * 3. `connector.active_tariff`: Fallback if the above are not present.
	 */
	const tariff = useMemo((): OmniTariff | null => {
		if (activeTariff) {
			return activeTariff
		} else if (session?.tariff) {
			return session.tariff
		} else if (connector?.active_tariff) {
			return connector.active_tariff
		}
		return null
	}, [activeTariff, connector?.active_tariff, session?.tariff])

	/**
	 * Used In: `PreChargingView` and `ChargingView`
	 */
	const gridItemList = useMemo((): DetailsGridItem[] => {
		const operatorNameItem: DetailsGridItem = {
			label: intl.formatMessage({
				id: 'useChargerItemLists.LabelCpo',
				defaultMessage: 'CPO'
			}),
			value: location?.operator?.name ? location.operator.name : '-',
			labelKey: 'cpo'
		}

		const maximumPowerItem: DetailsGridItem = {
			label: intl.formatMessage({
				id: 'useChargerItemLists.LabelMaxPower',
				defaultMessage: 'Max Power'
			}),
			value: `${connector?.max_electric_power ? connector.max_electric_power / 1000 : '-'} kW`,
			labelKey: 'max-power'
		}

		const activePowerItem: DetailsGridItem = {
			label: intl.formatMessage({
				id: 'useChargerItemLists.LabelActivePower',
				defaultMessage: 'Active Power'
			}),
			value: ((): string => {
				// Find the latest `POWER` dimension inside of `session`.
				if (session) {
					const isPowerDimension = (dimension?: OmniSessionChargingPeriodDimension): boolean => {
						return dimension?.type === OmniSessionChargingPeriodDimensionType.Power
					}

					const powerDimension = session.charging_periods
						?.findLast(({ dimensions = [] }): boolean => {
							return dimensions.some(isPowerDimension)
						})
						?.dimensions?.find(isPowerDimension)

					if (powerDimension?.volume !== undefined) {
						return `${powerDimension.volume / 1000} kW`
					} else {
						return 'N/A'
					}
				}
				return '- kW'
			})(),
			labelKey: 'active-power'
		}

		// Show `price_per_kwh` if it has a defined non-zero value.
		const costPerKwhItem: DetailsGridItem | null = tariff?.price_per_kwh?.incl_vat
			? {
					label: intl.formatMessage({
						id: 'useChargerItemLists.LabelCostPerKwh',
						defaultMessage: 'Cost per kWh'
					}),
					labelSuffix: (
						<InformationCircleFilledIcon className="h-3.5 w-3.5 text-typography-secondary" />
					),
					value: getTariffLabelValue(
						'price_per_kwh',
						connector,
						tariff,
						isAuthenticated,
						isUserSubscribed,
						true
					),
					onClick: toggleTariffModal,
					labelKey: 'cost-per-kwh'
				}
			: null

		// Show `price_per_min` if it has a defined non-zero value.
		const pricePerMinItem: DetailsGridItem | null = tariff?.price_per_min?.incl_vat
			? {
					label: intl.formatMessage({
						id: 'useChargerItemLists.LabelPricePerMin',
						defaultMessage: 'Price per min'
					}),
					labelSuffix: (
						<InformationCircleFilledIcon className="h-3.5 w-3.5 text-typography-secondary" />
					),
					value: getTariffLabelValue(
						'price_per_min',
						connector,
						tariff,
						isAuthenticated,
						isUserSubscribed,
						true
					),
					onClick: toggleTariffModal,
					labelKey: 'price-per-min'
				}
			: null

		const idlingFee = getTariffLabelValue(
			'price_per_min_parked',
			connector,
			tariff,
			isAuthenticated,
			isUserSubscribed,
			true
		)

		/**
		 * Handle conditional showing of Idling Fee at the Brand level.
		 */
		const idlingFeeItem: DetailsGridItem = {
			label: intl.formatMessage({
				id: 'useChargerItemLists.LabelIdlingFee',
				defaultMessage: 'Idling Fee'
			}),
			labelSuffix: (
				<InformationCircleFilledIcon className="h-3.5 w-3.5 text-typography-secondary" />
			),
			value: tariff?.price_per_min_parked?.incl_vat
				? idlingFee
				: intl.formatMessage({
						id: 'useChargerItemLists.LabelValueNotApplicable',
						defaultMessage: 'N/A'
					}),
			onClick: toggleTariffModal,
			labelKey: 'idling-fee'
		}

		// Brand-Dependent Display
		switch (BRAND) {
			case Brand.Kineta: {
				const sessionFeeItem: DetailsGridItem = {
					label: intl.formatMessage({
						id: 'useChargerItemLists.LabelSessionFee',
						defaultMessage: 'Session Fee'
					}),
					labelSuffix: (
						<InformationCircleFilledIcon className="h-3.5 w-3.5 text-typography-secondary" />
					),
					value: getTariffLabelValue(
						'price_flat',
						connector,
						tariff,
						isAuthenticated,
						isUserSubscribed,
						true
					),
					onClick: toggleTariffModal,
					labelKey: 'session-fee'
				}
				return [
					operatorNameItem,
					maximumPowerItem,
					activePowerItem,
					sessionFeeItem,
					costPerKwhItem,
					pricePerMinItem,
					tariff?.price_per_min_parked?.incl_vat ? idlingFeeItem : null
				].filter(Boolean) as DetailsGridItem[]
			}
			case Brand.SpGroup: {
				return [
					operatorNameItem,
					maximumPowerItem,
					costPerKwhItem,
					pricePerMinItem,
					idlingFeeItem
				].filter(Boolean) as DetailsGridItem[]
			}
			default:
				return [
					operatorNameItem,
					maximumPowerItem,
					activePowerItem,
					costPerKwhItem,
					pricePerMinItem,
					tariff?.price_per_min_parked?.incl_vat ? idlingFeeItem : null
				].filter(Boolean) as DetailsGridItem[]
		}
	}, [
		connector,
		intl,
		isAuthenticated,
		isUserSubscribed,
		session,
		tariff,
		toggleTariffModal,
		location?.operator?.name
	])

	/**
	 * Used In: `ChargingView`
	 */
	const rowsItemList = useMemo((): DetailsRowItem[] => {
		if (session) {
			const chargingFeeItem: DetailsRowItem = {
				label: intl.formatMessage({
					id: 'useChargerItemLists.LabelChargingFee',
					defaultMessage: 'Charging Fee'
				}),
				value: (
					<span
						className={classNames(
							isUserSubscribed ? 'text-typography-tertiary line-through' : null
						)}
					>
						<span>{formatCurrencyValue(session.total_cost?.incl_vat, session.currency)}</span>
					</span>
				)
			}

			const totalEnergyItem: DetailsRowItem = {
				label: intl.formatMessage({
					id: 'useChargerItemLists.LabelEnergyDelivered',
					defaultMessage: 'Energy Delivered'
				}),
				value: `${session.kwh?.toFixed(3) ?? '-'} kWh`
			}

			const sessionTimeItem: DetailsRowItem = {
				label: intl.formatMessage({
					id: 'useChargerItemLists.LabelSessionTime',
					defaultMessage: 'Session Time'
				}),
				value: ((): JSX.Element | string => {
					if (
						session.status &&
						[OmniSessionStatus.Active, OmniSessionStatus.Completed].includes(session.status)
					) {
						const firstTimeEnergyChargingPeriod = session.charging_periods?.find(
							(chargingPeriod): boolean => {
								const hasEnergyDimension =
									chargingPeriod.dimensions?.some((dimension): boolean => {
										return dimension.type === OmniSessionChargingPeriodDimensionType.Energy
									}) ?? false
								const hasTimeDimension =
									chargingPeriod.dimensions?.some((dimension): boolean => {
										return dimension.type === OmniSessionChargingPeriodDimensionType.Time
									}) ?? false
								return hasEnergyDimension && hasTimeDimension
							}
						)
						if (firstTimeEnergyChargingPeriod) {
							const chargingStartDateTime = firstTimeEnergyChargingPeriod.start_date_time
							return <TimeElapsedText startDateTime={chargingStartDateTime} />
						}
					}
					return '-'
				})()
			}

			return [sessionTimeItem, chargingFeeItem, totalEnergyItem]
		}
		return []
	}, [intl, isUserSubscribed, session])

	/**
	 * Used In: `PreChargingView` and `ChargingView`
	 */
	const tariffInformationItemList = useMemo((): TariffInformationItem[] => {
		const taxAcronym = getTaxAcronym(tariff?.country_code)

		const inclusiveTaxLabelSuffix = `(${intl.formatMessage(
			{
				id: 'useChargerItemLists.TotalCostAbbreviatedInclusiveTax',
				defaultMessage: 'Incl. {taxAcronym}'
			},
			{
				taxAcronym
			}
		)})`

		const noTaxAppliedLabelSuffix = `(${intl.formatMessage(
			{
				id: 'useChargerItemLists.TotalCostAbbreviatedNoTaxApplied',
				defaultMessage: 'No {taxAcronym} Appl.'
			},
			{
				taxAcronym
			}
		)})`

		// Show `price_per_kwh` if it has a defined non-zero value.
		const costPerKwhItem: TariffInformationItem | null = tariff?.price_per_kwh?.incl_vat
			? {
					label: intl.formatMessage({
						id: 'useChargerItemLists.LabelCostPerKwh',
						defaultMessage: 'Cost per kWh'
					}),
					labelSuffix: !hideTaxIndicators && inclusiveTaxLabelSuffix,
					value: getTariffLabelValue(
						'price_per_kwh',
						connector,
						tariff,
						isAuthenticated,
						isUserSubscribed,
						false
					)
				}
			: null

		const minimumFee = tariff?.min_price?.incl_vat

		const minimumFeeItem: TariffInformationItem = {
			label: intl.formatMessage({
				id: 'useChargerItemLists.LabelMinFee',
				defaultMessage: 'Min. Fee'
			}),
			labelSuffix: !hideTaxIndicators && noTaxAppliedLabelSuffix,
			value: formatCurrencyValue(minimumFee, tariff?.currency, 2)
		}

		// Show `price_per_min` if it has a defined non-zero value.
		const pricePerMinItem: TariffInformationItem | null = tariff?.price_per_min?.incl_vat
			? {
					label: intl.formatMessage({
						id: 'useChargerItemLists.LabelPricePerMin',
						defaultMessage: 'Price per min'
					}),
					labelSuffix: !hideTaxIndicators && inclusiveTaxLabelSuffix,
					value: getTariffLabelValue(
						'price_per_min',
						connector,
						tariff,
						isAuthenticated,
						isUserSubscribed,
						false
					)
				}
			: null

		const hoursAbbreviated = intl.formatMessage({
			id: 'formatTimeToString.LabelValueHourAbbreviated',
			defaultMessage: 'h'
		})

		const minutesAbbreviated = intl.formatMessage({
			id: 'formatTimeToString.LabelValueMinuteAbbreviated',
			defaultMessage: 'min'
		})

		const secondsAbbreviated = intl.formatMessage({
			id: 'formatTimeToString.LabelValueSecondAbbreviated',
			defaultMessage: 's'
		})

		const timeFormat: Record<string, [string, string]> = {
			hoursFormat: [hoursAbbreviated, hoursAbbreviated],
			minutesFormat: [minutesAbbreviated, minutesAbbreviated],
			secondsFormat: [secondsAbbreviated, secondsAbbreviated]
		}

		const idlingFee = getTariffLabelValue(
			'price_per_min_parked',
			connector,
			tariff,
			isAuthenticated,
			isUserSubscribed,
			false
		)

		const gracePeriodSeconds = tariff?.grace_period_seconds ?? 0

		const graceDuration = formatTimeToString(getTimeFromSeconds(gracePeriodSeconds), timeFormat)

		const graceDurationDetails = intl.formatMessage(
			{
				id: 'useChargerItemLists.LabelValueGraceSuffix',
				defaultMessage: '({freeDuration} grace period)'
			},
			{
				freeDuration: graceDuration
			}
		)

		/**
		 * Handle conditional showing of Idling Fee at the Brand level.
		 */
		const idlingFeeItem: TariffInformationItem = {
			label: intl.formatMessage({
				id: 'useChargerItemLists.LabelIdlingFee',
				defaultMessage: 'Idling Fee'
			}),
			labelSuffix: !hideTaxIndicators && noTaxAppliedLabelSuffix,
			value: ((): ReactNode => {
				if (tariff?.price_per_min_parked?.incl_vat) {
					if (typeof idlingFee === 'string') {
						return [idlingFee, gracePeriodSeconds > 0 ? graceDurationDetails : ''].join(' ')
					}
					return (
						<div className="flex flex-col space-y-0.5 2xs:flex-row 2xs:space-x-1 2xs:space-y-0">
							{idlingFee}
							{gracePeriodSeconds > 0 && <span>{graceDurationDetails}</span>}
						</div>
					)
				}
				return intl.formatMessage({
					id: 'useChargerItemLists.LabelValueNotApplicable',
					defaultMessage: 'N/A'
				})
			})()
		}

		// Brand-Dependent Display
		switch (BRAND) {
			case Brand.Kineta: {
				const sessionFee = getTariffLabelValue(
					'price_flat',
					connector,
					tariff,
					isAuthenticated,
					isUserSubscribed,
					false
				)

				const oneTimeFlatFeeText = intl.formatMessage({
					id: 'useChargerItemLists.LabelValueFlatFeeSuffix',
					defaultMessage: '(one time flat fee)'
				})

				const sessionFeeItem: TariffInformationItem = {
					label: intl.formatMessage({
						id: 'useChargerItemLists.LabelSessionFee',
						defaultMessage: 'Session Fee'
					}),
					labelSuffix: null, // TODO: Confirm if this is incl./excl. tax
					value:
						typeof sessionFee === 'string' ? (
							`${sessionFee} ${oneTimeFlatFeeText}`
						) : (
							<div className="flex flex-col space-y-0.5 2xs:flex-row 2xs:space-x-1 2xs:space-y-0">
								{sessionFee}
								<span>{oneTimeFlatFeeText}</span>
							</div>
						)
				}

				const usageFee = getTariffLabelValue(
					'price_per_min',
					connector,
					tariff,
					isAuthenticated,
					isUserSubscribed,
					false
				)

				const timeBeforeHoggingSeconds = tariff?.time_before_hogging_seconds ?? 0

				const timeBeforeHoggingDuration = formatTimeToString(
					getTimeFromSeconds(timeBeforeHoggingSeconds),
					timeFormat
				)

				const timeBeforeHoggingDetails = intl.formatMessage(
					{
						id: 'useChargerItemLists.LabelValueFreeSuffix',
						defaultMessage: '(first {freeDuration} free)'
					},
					{
						freeDuration: timeBeforeHoggingDuration
					}
				)

				/**
				 * Price per min with hogging details.
				 */
				const usageFeeItem: TariffInformationItem = {
					label: intl.formatMessage({
						id: 'useChargerItemLists.LabelUsageFee',
						defaultMessage: 'Usage Fee'
					}),
					labelSuffix: !hideTaxIndicators && inclusiveTaxLabelSuffix,
					value:
						typeof usageFee === 'string' ? (
							[usageFee, timeBeforeHoggingSeconds > 0 ? timeBeforeHoggingDetails : ''].join(' ')
						) : (
							<div className="flex flex-col space-y-0.5 2xs:flex-row 2xs:space-x-1 2xs:space-y-0">
								{usageFee}
								{timeBeforeHoggingSeconds > 0 && <span>{timeBeforeHoggingDetails}</span>}
							</div>
						)
				}

				return [
					sessionFeeItem,
					costPerKwhItem,
					usageFeeItem,
					tariff?.price_per_min_parked?.incl_vat ? idlingFeeItem : null,
					minimumFeeItem
				].filter(Boolean) as TariffInformationItem[]
			}
			case Brand.SpGroup: {
				return [
					costPerKwhItem,
					pricePerMinItem,
					idlingFeeItem, // Always show Idling Fee
					minimumFeeItem
				].filter(Boolean) as TariffInformationItem[]
			}
			default: {
				return [
					costPerKwhItem,
					pricePerMinItem,
					tariff?.price_per_min_parked?.incl_vat ? idlingFeeItem : null,
					minimumFeeItem
				].filter(Boolean) as TariffInformationItem[]
			}
		}
	}, [connector, hideTaxIndicators, intl, isAuthenticated, isUserSubscribed, tariff])

	return {
		gridItemList,
		rowsItemList,
		tariffInformationItemList
	}
}
