import { DetailedHTMLProps, ImgHTMLAttributes, memo } from 'react'

import chargerConnectorDark from './chargerConnectorDark.png'
import chargerConnectorLight from './chargerConnectorLight.png'

type ChargerConnectorImageProps = Omit<
	DetailedHTMLProps<ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>,
	'src'
> & {
	variant?: 'light' | 'dark'
}

const ChargerConnectorImage = ({
	alt = 'Connect Charger',
	variant = 'light',
	...props
}: ChargerConnectorImageProps): JSX.Element => {
	const imageSrc = variant === 'light' ? chargerConnectorLight : chargerConnectorDark
	return <img alt={alt} src={imageSrc} {...props} />
}

const MemoisedChargerConnectorImage = memo(ChargerConnectorImage)

export default MemoisedChargerConnectorImage
