import { useQueryClient } from '@tanstack/react-query'
import dayjs from 'dayjs'
import { ReactNode, useCallback, useEffect, useState } from 'react'
import { ScreenRoutePath } from 'src/App/router/hooks'
import Button from 'src/_shared/components/Button'
import Modal from 'src/_shared/components/Modal'
import ModalCard from 'src/_shared/components/Modal/components/ModalCard'
import PaymentMethodsList from 'src/_shared/components/PaymentMethodsList'
import ScreenContainer from 'src/_shared/components/ScreenContainer'
import TopBarButton from 'src/_shared/components/ScreenContainer/components/TopBarButton'
import Spinner from 'src/_shared/components/Spinner'
import CheckCircleFilledIcon from 'src/_shared/components/_icons/CheckCircleFilledIcon'
import CheckIcon from 'src/_shared/components/_icons/CheckIcon'
import ErrorFilledIcon from 'src/_shared/components/_icons/ErrorFilledIcon'
import TrashIcon from 'src/_shared/components/_icons/TrashIcon'
import { BRAND } from 'src/_shared/constants/env'
import { Brand } from 'src/_shared/enums/env'
import useAddTngPaymentMethod from 'src/_shared/hooks/useAddTngPaymentMethod'
import { useDeletePaymentMethodMutation } from 'src/_shared/mutations/payments'
import {
	PaymentsQueryKey,
	ROOT_PAYMENTS_QUERY_KEY,
	usePaymentMethodsQuery
} from 'src/_shared/queries/payments'
import { PaymentMethod } from 'src/_shared/types/payments'
import { classNames } from 'src/_shared/utils/elements'
import { formatDataTestId } from 'src/_shared/utils/string'
import { delay } from 'src/_shared/utils/time'

const AccountPaymentMethodsScreen = (): JSX.Element => {
	const [defaultPaymentMethod, setDefaultPaymentMethod] = useState<PaymentMethod | null>(null)

	const [paymentMethodToDelete, setPaymentMethodToDelete] = useState<PaymentMethod | null>(null)

	const [isEditing, setIsEditing] = useState<boolean>(false)

	const queryClient = useQueryClient()

	const { data: paymentMethods = [], isLoading: isPaymentMethodsLoading } = usePaymentMethodsQuery()

	const {
		isError: isDeletePaymentMethodError,
		isIdle: isDeletePaymentMethodIdle,
		isPending: isDeletePaymentMethodPending,
		isSuccess: isDeletePaymentMethodSuccess,
		mutateAsync: deletePaymentMethod,
		reset: resetDeletePaymentMethodMutation
	} = useDeletePaymentMethodMutation()

	const { addTngPaymentMethod, isAddTngPaymentMethodPending, isTngSetupIntentLoading } =
		useAddTngPaymentMethod()

	const toggleIsEditing = useCallback((): void => {
		setIsEditing((value): boolean => !value)
	}, [])

	const handleDeletePaymentMethod = useCallback((): void => {
		if (paymentMethodToDelete?._id) {
			void deletePaymentMethod(
				{ paymentMethodId: paymentMethodToDelete._id },
				{
					onSettled: (): void => {
						const handleSettled = async (): Promise<void> => {
							await delay(2500) // Delay briefly show to the user whether or not the payment method was deleted.
							await queryClient.invalidateQueries({
								queryKey: [ROOT_PAYMENTS_QUERY_KEY, PaymentsQueryKey.PaymentMethods]
							})
							resetDeletePaymentMethodMutation()
							if (paymentMethodToDelete._id === defaultPaymentMethod?._id) {
								setDefaultPaymentMethod(null)
							}
							setPaymentMethodToDelete(null)
						}
						void handleSettled()
					}
				}
			)
		}
	}, [
		defaultPaymentMethod?._id,
		paymentMethodToDelete?._id,
		queryClient,
		resetDeletePaymentMethodMutation,
		deletePaymentMethod
	])

	const handleDeletePaymentMethodModalClose = useCallback((): void => {
		if (isDeletePaymentMethodIdle) {
			setPaymentMethodToDelete(null)
		}
	}, [isDeletePaymentMethodIdle])

	const listItemRightRender = useCallback(
		(paymentMethod: PaymentMethod): JSX.Element => {
			const isExpiredPaymentMethod =
				!!paymentMethod.tokenExpiresAt && dayjs().isSameOrAfter(paymentMethod.tokenExpiresAt)

			const isDefaultPaymentMethod = paymentMethod._id === defaultPaymentMethod?._id

			if (isEditing) {
				return (
					<button
						data-testid={formatDataTestId([
							'apms-btn-delete-payment-method',
							paymentMethod._id ?? ''
						])}
						onClick={(): void => {
							setPaymentMethodToDelete(paymentMethod)
						}}
					>
						<span className="body-1-medium text-error-300">Delete</span>
					</button>
				)
			}
			return (
				<div className="flex items-center space-x-2">
					{isDefaultPaymentMethod && (
						<div className="flex items-center rounded-full border border-primary-800 bg-primary-800 bg-opacity-10 px-2 py-0.5">
							<span
								data-testid="apms-text-default-payment-method"
								className="caption-2-normal text-primary-800"
							>
								Default
							</span>
						</div>
					)}
					{isExpiredPaymentMethod && (
						<div className="flex items-center rounded-full border border-error-300 bg-error-300 bg-opacity-10 px-2 py-0.5">
							<span
								data-testid={`apms-text-expired-payment-method-${paymentMethod._id}`}
								className="caption-2-normal text-error-300"
							>
								Expired
							</span>
						</div>
					)}
					{!isDefaultPaymentMethod && !isExpiredPaymentMethod && (
						<button
							data-testid={`apms-btn-set-default-payment-method-${paymentMethod._id}`}
							className="body-1-normal cursor-pointer text-typography-secondary underline"
							onClick={(): void => {
								// FUTURE TODO: Handle Switching of Default Payment Method.
								setDefaultPaymentMethod(paymentMethod)
							}}
						>
							Set Default
						</button>
					)}
				</div>
			)
		},
		[defaultPaymentMethod, isEditing]
	)

	/**
	 * If all payment methods have been deleted, revert `isEditing` back to `false`.
	 */
	useEffect((): void => {
		if (isEditing && paymentMethods.length === 0) {
			setIsEditing(false)
		}
	}, [isEditing, paymentMethods.length])

	/**
	 * Initialise Default Payment Method
	 */
	useEffect((): void => {
		if (!defaultPaymentMethod && paymentMethods[0]) {
			// Currently assumes that we only have 1 payment method, hence it's also the default payment.
			// FUTURE TODO: Handle multiple payment methods.
			setDefaultPaymentMethod(paymentMethods[0])
		}
	}, [defaultPaymentMethod, paymentMethods])

	return (
		<ScreenContainer
			contentViewProps={{
				className: classNames(
					'px-5 py-6',
					isPaymentMethodsLoading ? 'items-center justify-center' : null
				)
			}}
			topBarProps={{
				centerRender: <h1 data-testid="apms-text-tb-header">Payment Methods</h1>,
				rightRender:
					paymentMethods.length > 0 ? (
						<TopBarButton onClick={toggleIsEditing}>
							{isEditing ? (
								<CheckIcon data-testid="apms-icon-tb-check" className="h-4" />
							) : (
								<TrashIcon data-testid="apms-icon-tb-trash" className="h-4" />
							)}
						</TopBarButton>
					) : null
			}}
			hideBottomBar
		>
			{isPaymentMethodsLoading ? (
				<Spinner />
			) : (
				<>
					{/* Payment Methods List */}
					<PaymentMethodsList
						className="mb-5"
						dataTestIdPrefix="apms"
						paymentMethods={paymentMethods}
						listItemRightRender={listItemRightRender}
					/>
					{/* Add/Replace Payment Method Button */}
					{((): ReactNode => {
						switch (BRAND) {
							// TNG eWallet
							case Brand.TouchNGo: {
								return (
									paymentMethods.length === 0 && (
										<Button
											data-testid="apms-btn-add-payment-method"
											variant="dashed"
											className="w-full"
											disabled={isTngSetupIntentLoading || isAddTngPaymentMethodPending}
											loading={isAddTngPaymentMethodPending}
											onClick={addTngPaymentMethod}
										>
											+ Add Payment Method
										</Button>
									)
								)
							}
							// Card Payment Method
							default: {
								return (
									!isEditing && (
										<Button
											data-testid={
												paymentMethods.length === 0 ? 'apms-btn-add-card' : 'apms-btn-replace-card'
											}
											variant="dashed"
											className="w-full"
											linkProps={{ to: ScreenRoutePath.AccountPaymentMethodsNew }}
										>
											+ {paymentMethods.length === 0 ? 'Add' : 'Replace'} Card
										</Button>
									)
								)
							}
						}
					})()}
				</>
			)}
			{/* Delete Payment Method Modal */}
			<Modal open={!!paymentMethodToDelete} onClose={handleDeletePaymentMethodModalClose}>
				<ModalCard
					className={classNames(
						'flex flex-col items-center',
						isDeletePaymentMethodError || isDeletePaymentMethodSuccess ? '!pb-10 !pt-8' : null
					)}
				>
					{((): JSX.Element => {
						if (isDeletePaymentMethodError) {
							return (
								<>
									<ErrorFilledIcon
										data-testid="apms-icon-error-remove-payment-method"
										className="mb-4 h-12 w-12 text-error-300"
									/>
									<h1
										data-testid="apms-header-error-remove-payment-method"
										className="mb-2 text-center"
									>
										Error
									</h1>
									<p
										data-testid="apms-text-error-remove-payment-method"
										className="body-2-light text-center"
									>
										Your payment method details were not removed.
									</p>
								</>
							)
						} else if (isDeletePaymentMethodSuccess) {
							return (
								<>
									<CheckCircleFilledIcon
										data-testid="apms-icon-success-remove-payment-method"
										className="mb-4 h-12 w-12 text-success-400"
									/>
									<h1
										data-testid="apms-header-success-remove-payment-method"
										className="mb-2 text-center"
									>
										Success
									</h1>
									<p
										data-testid="apms-text-success-remove-payment-method"
										className="body-2-light text-center"
									>
										Your payment method details have been removed.
									</p>
								</>
							)
						}
						return (
							<>
								<h1 className="mb-2 text-center">Notice</h1>
								<p
									data-testid="apms-text-alert-remove-payment-method"
									className="body-2-normal mb-8 text-center"
								>
									Are you sure you want to delete this payment method?
								</p>
								<div className="flex w-full flex-col space-y-4">
									<Button
										data-testid="apms-btn-confirm-remove-payment-method"
										variant="primary"
										disabled={isDeletePaymentMethodPending}
										loading={isDeletePaymentMethodPending}
										onClick={handleDeletePaymentMethod}
									>
										Confirm
									</Button>
									<Button
										data-testid="apms-btn-cancel-remove-payment-method"
										variant="secondary"
										disabled={isDeletePaymentMethodPending}
										onClick={handleDeletePaymentMethodModalClose}
									>
										Cancel
									</Button>
								</div>
							</>
						)
					})()}
				</ModalCard>
			</Modal>
		</ScreenContainer>
	)
}

export default AccountPaymentMethodsScreen
