import { useQueryClient } from '@tanstack/react-query'
import { ChangeEvent, FormEvent, useCallback, useEffect, useMemo, useState } from 'react'
import { ScreenRoutePath, useRouterNavigate } from 'src/App/router/hooks'
import Button from 'src/_shared/components/Button'
import Input from 'src/_shared/components/Input'
import Modal from 'src/_shared/components/Modal'
import ModalCard from 'src/_shared/components/Modal/components/ModalCard'
import ScreenContainer from 'src/_shared/components/ScreenContainer'
import Spinner from 'src/_shared/components/Spinner'
import CheckCircleFilledIcon from 'src/_shared/components/_icons/CheckCircleFilledIcon'
import CheckIcon from 'src/_shared/components/_icons/CheckIcon'
import { useAuthContext } from 'src/_shared/hooks/useAuthContext'
import { useUserValidateEmailMutation } from 'src/_shared/mutations/auth'
import { useUserProfileEditMutation } from 'src/_shared/mutations/user'
import { ROOT_USER_QUERY_KEY, UserQueryKey } from 'src/_shared/queries/user'
import { formatDataTestId } from 'src/_shared/utils/string'
import { delay } from 'src/_shared/utils/time'
import isEmail from 'validator/lib/isEmail'

import { FormFieldKey } from './enums'
import { FormField } from './types'

const DEBOUNCE_CHECK_DELAY = 375

const AccountProfileEditScreen = (): JSX.Element => {
	const { user, accessToken } = useAuthContext()

	const [formValues, setFormValues] = useState<Record<FormFieldKey, string>>({
		[FormFieldKey.Name]: user?.name ? user.name : '',
		[FormFieldKey.Email]: user?.email ? user.email : '',
		[FormFieldKey.LicensePlateNumber]: user?.vehiclePlateNumber ? user.vehiclePlateNumber : ''
	})

	const queryClient = useQueryClient()

	const navigate = useRouterNavigate()

	const name = formValues[FormFieldKey.Name]

	const email = formValues[FormFieldKey.Email]

	const {
		error: saveUserProfileDetailsError,
		isPending: isSaveUserProfileDetailsPending,
		isSuccess: isSaveUserProfileDetailsSuccess,
		mutateAsync: saveUserProfileDetails,
		reset: resetUserProfileEditMutation
	} = useUserProfileEditMutation()

	const {
		error: validateEmailError,
		isError: isValidateEmailError,
		isSuccess: isValidateEmailSuccess,
		mutateAsync: validateEmail,
		reset: resetUserValidateEmailMutation
	} = useUserValidateEmailMutation()

	// Note: Pending more granular error messages from CPO BE.
	const formFields = useMemo((): FormField[] => {
		const commonErrorMessage = saveUserProfileDetailsError?.response?.data.message

		const nameFormField: FormField = {
			key: FormFieldKey.Name,
			label: 'Name',
			errorMessage: ((): string | undefined => {
				if (!name) {
					return 'Name is required'
				}
				return commonErrorMessage?.toLowerCase().includes('name') ? commonErrorMessage : undefined
			})(),
			showRequiredMark: true
		}

		const emailFormField: FormField = {
			key: FormFieldKey.Email,
			label: 'Email',
			errorMessage: ((): string | undefined => {
				if (!email) {
					return 'Email is required'
				}
				if (!!email && !isEmail(email)) {
					return `${email} is not a valid email format`
				}
				if (isValidateEmailError) {
					const { message: errorMessage, status: statusCode } =
						validateEmailError.response?.data ?? {}
					switch (true) {
						case statusCode === 409:
							return 'Email address is already in use'
						// Fallback to error message if the status codes above are not encountered.
						case !!errorMessage:
							return errorMessage
						default:
							return 'Oops! Something went wrong'
					}
				}
				return commonErrorMessage?.toLowerCase().includes('email') ? commonErrorMessage : undefined
			})(),
			showRequiredMark: true
		}

		const licensePlateNumberFormField: FormField = {
			key: FormFieldKey.LicensePlateNumber,
			label: 'License Plate No.',
			errorMessage: commonErrorMessage?.toLowerCase().includes('vehicle')
				? commonErrorMessage
				: undefined
		}

		return [nameFormField, emailFormField, licensePlateNumberFormField]
	}, [email, isValidateEmailError, name, validateEmailError, saveUserProfileDetailsError])

	const isFormChanged = useMemo((): boolean => {
		return (
			formValues[FormFieldKey.Name] !== user?.name ||
			formValues[FormFieldKey.Email] !== user.email ||
			formValues[FormFieldKey.LicensePlateNumber] !== user.vehiclePlateNumber
		)
	}, [user, formValues])

	const isFormValid = useMemo((): boolean => {
		const isNameFilledIn = !!formValues[FormFieldKey.Name].trim()
		const isEmailFilledIn = !!email
		const isEmailChanged = email !== user?.email
		const hasNoErrorMessages = formFields.every(({ errorMessage }): boolean => {
			return !errorMessage
		})
		return (
			isNameFilledIn &&
			isEmailFilledIn &&
			hasNoErrorMessages &&
			(!isEmailChanged || isValidateEmailSuccess) &&
			isFormChanged
		)
	}, [email, formFields, formValues, isFormChanged, isValidateEmailSuccess, user?.email])

	const handleFormChange = useCallback(
		(key: FormFieldKey) =>
			(event: ChangeEvent<HTMLInputElement>): void => {
				const formattedValue = ((): string => {
					const formValue = event.currentTarget.value
					switch (key) {
						case FormFieldKey.Name:
						case FormFieldKey.LicensePlateNumber:
							return formValue.replace(/\s{2,}/g, ' ')
						default:
							return formValue.replace(/\s/g, '')
					}
				})()
				setFormValues(
					(values: Record<FormFieldKey, string>): Record<FormFieldKey, string> => ({
						...values,
						[key]: formattedValue
					})
				)
				// Clears common error.
				if (saveUserProfileDetailsError) {
					resetUserProfileEditMutation()
				}
				// Clears email validation error/success.
				if (key === FormFieldKey.Email) {
					resetUserValidateEmailMutation()
				}
			},
		[saveUserProfileDetailsError, resetUserProfileEditMutation, resetUserValidateEmailMutation]
	)

	const handleSaveClick = useCallback(
		(event: FormEvent<HTMLFormElement>): void => {
			// Prevent default form submission behaviour.
			event.preventDefault()

			if (isFormValid) {
				const vehiclePlateNumber = formValues[FormFieldKey.LicensePlateNumber]
				void saveUserProfileDetails(
					{
						name,
						email,
						vehiclePlateNumber
					},
					{
						onSuccess: (): void => {
							const handleSuccess = async (): Promise<void> => {
								await delay(2500) // Delay briefly to show the user whether or not their profile changes were saved.
								await queryClient.invalidateQueries({
									queryKey: [ROOT_USER_QUERY_KEY, UserQueryKey.UserInfo, { accessToken }]
								})
								resetUserProfileEditMutation()
								navigate(ScreenRoutePath.AccountProfile, { replace: true })
							}
							void handleSuccess()
						}
					}
				)
			}
		},
		[
			accessToken,
			email,
			formValues,
			isFormValid,
			name,
			queryClient,
			navigate,
			resetUserProfileEditMutation,
			saveUserProfileDetails
		]
	)

	/**
	 * Initialise Form Values
	 */
	useEffect((): void => {
		if (user) {
			setFormValues({
				[FormFieldKey.Name]: user.name ? user.name : '',
				[FormFieldKey.Email]: user.email ? user.email : '',
				[FormFieldKey.LicensePlateNumber]: user.vehiclePlateNumber ? user.vehiclePlateNumber : ''
			})
		}
	}, [user])

	/**
	 * Email Validity Check
	 */
	useEffect((): (() => void) | undefined => {
		if (isEmail(email) && email !== user?.email) {
			const timeoutId = setTimeout((): void => {
				void validateEmail({
					email
				})
			}, DEBOUNCE_CHECK_DELAY)
			return (): void => {
				clearTimeout(timeoutId)
			}
		}
	}, [email, user?.email, validateEmail])

	return (
		<ScreenContainer
			contentViewProps={{
				className: 'px-5 py-6'
			}}
			topBarProps={{ centerRender: <h1>Edit Profile</h1> }}
			hideBottomBar
		>
			{!user ? (
				<div className="flex flex-grow flex-col items-center justify-center">
					<Spinner />
				</div>
			) : (
				<form
					data-testid="apes-form-edit-profile"
					className="flex flex-grow flex-col"
					onSubmit={handleSaveClick}
				>
					<div className="flex flex-grow flex-col space-y-5">
						{formFields.map(
							({ key, label, errorMessage, showRequiredMark }, index): JSX.Element => {
								const commonProps = {
									key: index,
									id: key,
									name: key,
									value: formValues[key],
									label,
									disabled: isSaveUserProfileDetailsPending,
									autoComplete: 'off',
									description: errorMessage,
									error: !!errorMessage,
									showRequiredMark,
									onChange: handleFormChange(key)
								}
								switch (key) {
									case FormFieldKey.Email: {
										return (
											<Input
												data-testid={formatDataTestId(['apes', key])}
												{...commonProps}
												endAdornment={
													isValidateEmailSuccess && (
														<div className="p-1">
															<CheckIcon className="h-6 w-6 text-typography-primary" />
														</div>
													)
												}
											/>
										)
									}
									default:
										return <Input data-testid={formatDataTestId(['apes', key])} {...commonProps} />
								}
							}
						)}
					</div>
					<div className="flex flex-col space-y-2">
						<Button
							data-testid="apes-btn-save-profile"
							className="w-full"
							disabled={!isFormValid || isSaveUserProfileDetailsPending}
							loading={isSaveUserProfileDetailsPending}
						>
							Save
						</Button>
					</div>
				</form>
			)}
			{/* Success Modal */}
			<Modal open={isSaveUserProfileDetailsSuccess}>
				<ModalCard className="flex flex-col items-center !pb-10 !pt-8">
					<CheckCircleFilledIcon
						data-testid="apes-icon-success-save-profile"
						className="mb-4 h-12 w-12 text-success-400"
					/>
					<h1 data-testid="apes-header-success-save-profile" className="mb-2 text-center">
						Success
					</h1>
					<p data-testid="apes-text-success-save-profile" className="body-2-light text-center">
						Your profile has been updated and saved.
					</p>
				</ModalCard>
			</Modal>
		</ScreenContainer>
	)
}

export default AccountProfileEditScreen
